<template>
    <div>
        <div :class="{'is-loading-active flex items-center': isLoading==true,'is-loading-not-active':isLoading==false}" > 
            <div class="flex">
                <div class="m-auto">
                    <img src="@/assets/foncabsa-icon.svg" class="h-60 icon-animation">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default{
    name:"vueLoading",
    props:{
        isLoading:{
            type:Boolean,
            default: () => false
        }
    }
}

</script>


<style scoped>
.is-loading-active{
    position: fixed;
    top: 0;
	right: 0;
	bottom: 0;
	left: 0;
    z-index: 99999;
    background: rgba(0,0,0,0.6);
	opacity:1;
    justify-content: center;
  align-items: center;
}

.is-loading-not-active{
    display: none;
    position: relative;
    top: 0;
	right: 0;
	bottom: 0;
	left: 0;
    z-index: 99999;
    background: rgba(0,0,0,0.6);
	opacity:0;
}

.icon-animation{
    animation-duration: 2s;
    animation-name: rotate;
    animation-iteration-count: infinite;
}

@keyframes rotate {
    from{
        transform:  rotate(0deg); 
    }
    to{ 
        transform: rotate(180deg); 
    }

}

</style>